import { defaultTranslationKeysWithNamespaces } from '@ori-appshell/app-loaders';
import { type OnlyHeaderLayoutPageProps } from '@ori-appshell/layouts';
import { getOnlyHeaderLayoutProps } from '@ori-appshell/layouts/server';
import type { QueryParams } from '@ori-appshell/rendering';
import { parseTenantLanguageParams } from '@ori-appshell/rendering';
import type { CatalogueIPaperContainerProps } from '@ori/catalogue-ipaper';
import {
  CatalogueIPaperContainer,
  defaultTranslations,
  getCatalogueIPaperData,
  translationsNamespace,
} from '@ori/catalogue-ipaper';
import type { GetStaticPaths, GetStaticProps, GetStaticPropsResult, NextPage } from 'next';
import { PageFeatures } from '~/common-data/PageFeatures';
import { MISSING_CATALOGUE_CODE_MESSAGE, PRODUCTS, REVALIDATE_INTERVAL_IN_SECONDS } from '~/constants';
import { PACKAGE_NAME } from '~/constants-generated';
import { CatalogueLayout } from '~/containers/CatalogueLayout';
import { catalogueIPaperMainLogger } from '~/logger';
import { getCatalogueType } from '~/utils';

type PageQueryParams = QueryParams & {
  catalogueCode: string[];
};

const catalogueIpaperTranslations = {
  [translationsNamespace]: defaultTranslations,
  ...defaultTranslationKeysWithNamespaces,
};

type CatalogueIpaperTranslations = typeof catalogueIpaperTranslations;

interface CatalogueIPaperProps extends Omit<CatalogueIPaperContainerProps, 'translations'> {
  layoutPage: OnlyHeaderLayoutPageProps<CatalogueIpaperTranslations>;
}

const logger = catalogueIPaperMainLogger.createChild('getStaticProps');

export const getStaticPaths: GetStaticPaths = async () => ({
  paths: [],
  fallback: 'blocking',
});

export const getStaticProps: GetStaticProps<CatalogueIPaperProps, PageQueryParams> = async ({ params }) => {
  try {
    const { language, tenant } = parseTenantLanguageParams(params);
    const { catalogueCode: catalogueCodeParam } = params ?? {};
    const parsedCatalogueCode = catalogueCodeParam?.join('/');

    if (parsedCatalogueCode) {
      const { servicesUrls, layoutPage } = await getOnlyHeaderLayoutProps<CatalogueIpaperTranslations>({
        language,
        pageId: `${PRODUCTS}/${parsedCatalogueCode}`,
        sourceAppName: PACKAGE_NAME,
        tenant,
        defaultTranslations: catalogueIpaperTranslations,
        pageFeatures: Object.values(PageFeatures),
      });

      const { catalogueCode, config } = await getCatalogueIPaperData({
        catalogueType: getCatalogueType(parsedCatalogueCode),
        language,
        staticApiUrl: servicesUrls.staticApi.baseUrl,
        tenant,
      });
      const resultProps: GetStaticPropsResult<CatalogueIPaperProps> = {
        props: {
          catalogueCode: catalogueCode ?? parsedCatalogueCode,
          config,
          isFlyer: false,
          language,
          layoutPage,
        },
        revalidate: REVALIDATE_INTERVAL_IN_SECONDS * 2,
      };

      return resultProps;
    }

    logger.warn(MISSING_CATALOGUE_CODE_MESSAGE, { catalogueCode: parsedCatalogueCode });

    return { notFound: true, revalidate: REVALIDATE_INTERVAL_IN_SECONDS * 2 };
  } catch (error: unknown) {
    logger.error(new Error('Failed to prepare data', { cause: error }));
    throw error;
  }
};

const Page: NextPage<CatalogueIPaperProps> = ({ catalogueCode, config, isFlyer, language, layoutPage }) => (
  <CatalogueLayout
    useOpenedMinibagOnDesktop
    layoutPage={layoutPage}
  >
    <CatalogueIPaperContainer
      catalogueCode={catalogueCode}
      config={config}
      isFlyer={isFlyer}
      language={language}
    />
  </CatalogueLayout>
);

export default Page;
